import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Home from "../src/layouts/Home";
import NotFound from "../src/layouts/NotFound";
import Loading from "../src/components/Loading";
import Blog from "./layouts/Blog";
import BlogPost from "./layouts/BlogPost";
import data from "./utils/data.json";
import Community from "./layouts/Community";
import Events from "./layouts/Events";
import Contacts from "./layouts/Contacts";
import BookSpace from "./layouts/BookSpace";

function App() {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    // Simulate a brief delay using setTimeout
    setTimeout(() => {
      setPageLoaded(true);
    }, 1000); // Adjust the delay time as needed (1 second in this case)
  }, []);
  
  return (
    <>
      {!pageLoaded ? (
        <Loading />
      ) : (
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/blog" element={<Blog posts={data} />} />
            <Route path="/blog/:id" element={<BlogPost data={data} />} />
            <Route path="/communities" element={<Community />} />
            <Route path="/events" element={<Events />} />
            <Route path="/contact" element={<Contacts />} />
            <Route path="/book-a-space" element={<BookSpace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      )}
      {/* the power to greatness is in continue */}
    </>
  );
}

export default App;

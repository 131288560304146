export const plansData = [
	{ title: 'Drag & Drop Builder' },
	{ title: "1,000's of Templates" },
	{ title: 'Blog Support Tools' },
	{ title: 'eCommerce Store' },
];
// export const PartTimePlan = [
// 	{
// 		title:
// 			'A plan to your specific needs by just selecting individual services:;',
// 	},
// 	{ title: 'Mailing Address' },
// 	{ title: 'Mail Handling' },
// 	{ title: 'Phone Services' },
// 	{ title: 'Meeting Room Access' },
// 	{ title: 'Reception Services' },
// 	{ title: 'Digital Mail Management' },
// 	{ title: 'Additional Administrative Support' },
// ];
export const CustomOfficePlan = [
	{
		title:
			'A plan to your specific needs by just selecting individual services:;',
	},
	{ title: 'Mailing Address' },
	{ title: 'Mail Handling' },
	{ title: 'Phone Services' },
	{ title: 'Meeting Room Access' },
	{ title: 'Reception Services' },
	{ title: 'Digital Mail Management' },
	{ title: 'Additional Administrative Support' },
];
export const PremiumOfficePlan = [
	{ title: 'Professional Mailing Address;' },
	{ title: 'Mail and Package Receipt' },
	{ title: 'Mail Forwarding (Up to 4x per month)' },
	{ title: 'Virtual Phone Number with Call Forwarding and Voicemail' },
	{ title: 'Access to Meeting Rooms (Generous monthly allocation)' },
];
export const StandardOfficePlan = [
	{ title: 'Professional Mailing Address;' },
	{ title: 'Mail and Package Receipt' },
	{ title: 'Mail Forwarding (Up to 2x per month)' },
	{ title: 'Virtual Phone Number with Call Forwarding' },
	{ title: 'Access to Meeting Rooms (Limited hours included)' },
];
export const BasicOfficePlan = [
	{ title: 'Professional Mailing Address;' },
	{ title: 'Mail and Package Receipt' },
	{ title: 'Mail Forwarding (Additional fee per forwarding)' },
	{ title: 'Access to Meeting Rooms (Discounted rates)' },
];
export const HotDeskPlans = [
	{ title: 'No dedicated desk;' },
	{ title: 'Access to common areas' },
	{ title: 'Flexible billing options' },
];
export const HotDeskPrice = [
	{ amount: 2500, period: 'Daily' },
	{ amount: 1200, period: 'Weekly' },
	{ amount: 60000, period: 'Monthly' },
];
export const DedicatedDeskPlan = [
	{ title: 'Dedicated desk;' },
	{ title: 'Personal storage or locker included' },
	{ title: 'Monthly or longer-term commitments' },
];
export const MeetingRoomPlan = [
	{ title: 'Access to meeting rooms and conference facilities.' },
	{ title: 'Hourly or daily booking options.' },
	{ title: 'Ideal for individuals and businesses' },
];

export const MeetingRoomPrice = [
	{ amount: 5000, period: 'Hour' },
	{ amount: 70000, period: 'Daily' },
];
export const CorporatePlan = [
	{ title: 'Access to meeting rooms and conference facilities.' },
	{ title: 'Hourly or daily booking options.' },
	{ title: 'Ideal for communities and businesses' },
];

export const CorporatePlanPrice = [
	{ amount: 5000, place: 'Board Room', period: 'Hour' },
	{ amount: 70000, place: 'Meeting room', period: 'Hour' },
];
export const CommunityEventPlan = [
	{
		title:
			'A plan to your specific needs by just selecting individual services:;',
	},
	{ title: 'Mailing Address' },
	{ title: 'Mail Handling' },
	{ title: 'Phone Services' },
	{ title: 'Meeting Room Access' },
	{ title: 'Reception Services' },
	{ title: 'Digital Mail Management' },
	{ title: 'Additional Administrative Support' },
];
export const PartTimePlan = [
	{ title: 'No dedicated desk;' },
	{ title: 'Offer reduced rates for limited access' },
];

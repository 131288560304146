import React from "react";
import LogoDark from "../assets/images/OfficeBOX-logo-dark.png";
import LogoLight from "../assets/images/OfficeBOX-logo-light.png";

const Logo = ({ darkMode }) => {
  const logoSrc = darkMode ?  LogoLight :  LogoDark;

  return (
    <img
      src={logoSrc}
      alt="Logo"
      title="Loading State"
      className="w-40" // Adjust the classes as needed
    />
  );
};

export default Logo;

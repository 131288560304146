import React from "react";
import Fun from "../assets/Art.jpg";
import Image from "../assets/images/workspace (4).jpg";

const EventList = () => {
  return (
    <>
      <div className="w-full justify-center items-center flex">
        <div className="w-full justify-center gap-4 lg:gap-8 flex-col items-center flex mx-5 lg:max-w-6xl section">
          <div className="flex justify-center items-center w-full flex-col md:flex-row p-1 gap-5">
            <div className="w-full sm:max-w-md h-72 flex justify-center items-center  p-px aspect-square bg-slate-200 shadow-md rounded-md">
              <img
                src={Fun}
                className="w-full h-full rounded-2xl object-cover bg-center"
                alt=""
              />
            </div>
            <div className="w-full md:text-start text-center">
              <h1 className="font-bold text-lg text-gray-600 dark:text-gray-100">
                Fun and Entertainment
              </h1>
              {/* <h3 className="font-medium text-sm text-gray-600 pt-1">
                Discover ART House: Where Creativity Finds Its Home
              </h3> */}
              <p className="text-sm text-gray-600 pt-2 dark:text-gray-100">
                We understand that life isn't all about work. Enjoy social
                gatherings, live music, and themed events that provide a break
                from the daily routine. Relax, unwind, and have a great time
                with fellow attendees.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center w-full flex-col md:flex-row p-1 gap-5">
            <div className="w-full sm:max-w-md h-72 flex justify-center items-center  p-px aspect-square bg-slate-200 shadow-md rounded-md">
              <img
                src={Image}
                className="w-full h-full rounded-2xl object-cover bg-center"
                alt=""
              />
            </div>
            <div className="w-full md:text-start text-center">
              <h1 className="font-bold text-lg text-gray-600  dark:text-gray-100">
                Community Building
              </h1>
              {/* <h3 className="font-medium text-sm text-gray-600 pt-1">
                Discover ART House: Where Creativity Finds Its Home
              </h3> */}
              <p className="text-sm text-gray-600 pt-2  dark:text-gray-100">
                At Office Box, we believe in the power of community. Our events
                bring people together, fostering a sense of belonging and
                camaraderie. Join us in celebrating the diversity of our
                community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventList;

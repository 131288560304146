import React, { useState } from 'react';
import DateTimePicker from './DateTimePicker';
import Select from './Select';
import Logo from '../assets/images/OfficeBOX-logo-dark.png';
import useFormValidator from './../hooks/useFormValidator';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
const options = [
	{ id: 1, name: 'Part-Time Plan' },
	{ id: 2, name: 'Hot Desk Plan' },
	{ id: 3, name: 'Dedicated Desk Plan' },
	{ id: 4, name: 'Meeting Room Plan' },
	{ id: 5, name: 'Basic Office Plan' },
	{ id: 6, name: 'Standard Office Plan' },
	{ id: 7, name: 'Premium Office Plan' },
	{ id: 8, name: 'Custom Office Plan' },
];
const initialFormData = {
	name: '',
	email: '',
	numOfSeats: 1,
	selectedOption: options[0],
	selectedDate: '',
};

const BookingForm = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState(initialFormData);
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSelectChange = (selectedOption) => {
		setFormData({
			...formData,
			selectedOption,
		});
	};
	const handleDateChange = (selectedDate) => {
		setFormData({
			...formData,
			selectedDate,
		});
	};

	const handleGoBack = () => {
		setFormData(() => initialFormData);
		navigate(-1);
	};
	const { errors, validateForm } = useFormValidator();
	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm(formData)) {
			setLoading(true);
			// Form is valid, you can handle submission logic here
			// You can handle form submission logic here, such as sending data to a server.
			setTimeout(() => {
				console.log('Form submitted:', formData);
				setLoading(false);
				setFormData(() => initialFormData);
			}, 2000);
		} else {
			console.log('Form has errors');
		}
	};

	return (
		<>
			<div className="container mx-auto overflow-y-auto relative">
				<div className="mb-2 justify-center items-center flex w-full">
					<img src={Logo} alt="" className="w-28 md:w-36" />
				</div>
				<div className="absolute right-0 z-10 text-slate-700 hover:text-slate-600 top-0 cursor-pointer rounded-full p-1 hover:bg-gray-100 ease-in-out transition-all duration-500">
					<FaTimes className="h-5 w-5" onClick={handleGoBack}></FaTimes>
				</div>
				<hr className="mb-4" />
				<form onSubmit={handleSubmit}>
					<div className=" flex justify-center w-full items-center gap-3 flex-col md:flex-row">
						<div className="w-full mb-4">
							<label
								htmlFor="name"
								className="block text-gray-700 font-medium mb-1"
							>
								Full name
							</label>
							<input
								type="text"
								id="name"
								name="name"
								className="border rounded-lg px-3 py-2 w-full"
								value={formData.name}
								onChange={handleInputChange}
								required
							/>
							<div className="text-red-500 text-xs py-0.5">{errors.name}</div>
						</div>
						<div className="w-full mb-4">
							<label
								htmlFor="email"
								className="block text-gray-700 font-medium mb-1"
							>
								Email
							</label>
							<input
								type="email"
								id="email"
								name="email"
								className="border rounded-lg px-3 py-2 w-full"
								value={formData.email}
								onChange={handleInputChange}
								required
							/>
							<div className="text-red-500 text-xs py-0.5">{errors.email}</div>
						</div>
					</div>
					<div className="mb-4 w-full">
						<label
							htmlFor="selectOption"
							className="block text-gray-700 font-medium"
						>
							Select Plan
						</label>
						<Select
							options={options}
							defaultOption={formData.selectedOption}
							onSelect={() => handleSelectChange}
						/>
					</div>
					<div className=" flex justify-center w-full items-center gap-3 flex-col md:flex-row">
						<div className="mb-4 w-full">
							<label
								htmlFor="numOfSeats"
								className="block text-gray-700 font-medium mb-1"
							>
								Number of Seats
							</label>
							<input
								type="number"
								id="numOfSeats"
								name="numOfSeats"
								className="border rounded-lg px-3 py-2 w-full"
								value={formData.numOfSeats}
								onChange={handleInputChange}
								required
							/>
							<div className="text-red-500 text-xs py-0.5">
								{errors.numOfSeats}
							</div>
						</div>
						<div className="mb-4 w-full">
							<label className="block text-gray-700 font-medium mb-1">
								Date and Time
							</label>
							<DateTimePicker
								selectedDate={formData.selectedDate}
								handleDateChange={handleDateChange}
							/>
							<div className="text-red-500 text-xs py-0.5">
								{errors.selectedDate}
							</div>
						</div>
					</div>
					<button
						type="submit"
						disabled={loading}
						className="bg-primary w-full hover:bg-blue-600 mt-2 text-white font-medium py-3 px-4 rounded-xl"
					>
						{loading ? 'Loading' : 'Book Now'}
					</button>
				</form>
			</div>
		</>
	);
};

export default BookingForm;

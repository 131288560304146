import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { AiOutlineCheck } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';

const Select = ({ options, defaultOption, onSelect }) => {
	const [selected, setSelected] = useState(defaultOption);

	useEffect(() => {
		onSelect(selected);
	}, [selected, onSelect]);

	// // fix select re-rendering
	const handleSelect = (option) => {
		if (option !== selected) {
			setSelected(option);
		}
	};

	return (
		<div className="relative mt-1">
			<Listbox>
				{({ open }) => (
					<>
						<Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-3 pl-3 pr-10 text-left shadow focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
							<span className="block truncate">{selected.name}</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
								<BiChevronDown
									className={`h-5 w-5 ${
										open ? 'transform rotate-180' : 'text-gray-400'
									}`}
									aria-hidden="true"
								/>
							</span>
						</Listbox.Button>
						<Transition
							as="div"
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							{open && (
								<Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{options.map((option, optionIdx) => (
										<Listbox.Option
											key={optionIdx}
											className={({ active }) =>
												`relative cursor-default select-none py-2 pl-10 pr-4 ${
													active ? 'bg-blue-100 text-primary' : 'text-gray-900'
												}`
											}
											value={option}
											onClick={() => handleSelect(option)}
										>
											{({ selected }) => (
												<>
													<span
														className={`block truncate ${
															selected ? 'font-medium' : 'font-normal'
														}`}
													>
														{option.name}
													</span>
													{selected ? (
														<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-800">
															<AiOutlineCheck
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							)}
						</Transition>
					</>
				)}
			</Listbox>
		</div>
	);
};

export default Select;

'use client';

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ButtonGroup from './ButtonGroup';
import {
	BasicOfficePlan,
	CustomOfficePlan,
	DedicatedDeskPlan,
	HotDeskPlans,
	HotDeskPrice,
	MeetingRoomPlan,
	MeetingRoomPrice,
	PartTimePlan,
	PremiumOfficePlan,
	StandardOfficePlan,
	CorporatePlanPrice,
	CorporatePlan,
} from '../utils/data';
import { Link } from 'react-router-dom';

const Plan = ({ title }) => {
	return (
		<div className="flex items-center gap-2  dark:text-white text-black">
			<AiOutlineCheckCircle className="text-primary text-xl" />
			<span className="text-xs">{title}</span>
		</div>
	);
};

const PricingCard = ({ name, title, price, btnText, trail, plans }) => {
	return (
		<div className="mx-2 md:mx-3 cursor-pointer p-10 transition-all hover:shadow-lg flex flex-col gap-8 rounded-3xl border-neutral-200 border">
			<div className="flex flex-col gap-3">
				<h2 className="text-xl font-semibold capitalize dark:text-white text-black">
					{name}
				</h2>
				<span className="text-neutral-400 text-sm">{title}</span>
			</div>
			<div className="flex flex-col gap-5">
				{plans?.map((plan, index) => (
					<Plan key={index} title={plan.title} />
				))}
			</div>
			<div className="mx-auto">
				{Array.isArray(price) ? (
					price.map((item, index) => (
						<div
							key={index}
							className="text-3xl text-center  font-dm leading-none flex items-center pb-1 mb-4"
						>
							<span className="dark:text-white font-medium font-dm text-black">
								₦{item.amount}
							</span>
							<span className="text-lg ml-1 font-normal text-neutral-400">
								/{item.period}
							</span>
						</div>
					))
				) : (
					<div className="text-3xl text-center  font-dm leading-none flex items-center pb-4 mb-4">
						<span className="dark:text-white font-medium text-black">
							₦{price.amount}
						</span>
						<span className="text-lg ml-1 font-normal text-neutral-400">
							/{price.period}
						</span>
					</div>
				)}
				<Link to="/book-a-space" className="w-full">
					<button className="w-full capitalize md:text-base text-sm hover:bg-primary hover:shadow-md hover:shadow-primary hover:border-2 border-2 border-transparent py-3 px-6 text-white bg-primary hover:border-primary hover:text-white rounded-full">
						{btnText}
					</button>
				</Link>
				<span className="w-full justify-center items-center flex text-primary mt-5 font-semibold animate-bounce cursor-pointer">
					{trail}
				</span>
			</div>
		</div>
	);
};

const Pricing = () => {
	const [plan, setPlan] = useState('Co-working Plan');
	return (
		<section
			className="relative section container h-full mx-auto px-0 xl:px-16 flex flex-col gap-5"
			id="pricing"
		>
			<div>
				<span className="service-name text-center ">PRICING PLAN</span>
				<h2 className="section-title text-center  dark:text-white text-black">
					Choose your pricing policy
				</h2>
			</div>

			<div
				className="relative transition-all flex lg:gap-3 gap-1
       mx-auto w-fit bg-slate-100  dark:bg-gray-900 dark:border-white border p-2 rounded-full"
			>
				<button
					onClick={() => setPlan('Co-working Plan')}
					className={`
          ${
						plan === 'Co-working Plan'
							? 'text-white bg-primary duration-500 transition-all'
							: 'text-primary'
					}
          z-[1] capitalize lg:text-base text-xs hover:border-2 border-2 border-transparent py-3 lg:px-5 px-3 hover:border-primary rounded-full`}
				>
					Co-working Plan
				</button>
				<button
					onClick={() => setPlan('Virtual Office Plans')}
					className={`
          ${
						plan === 'Virtual Office Plans'
							? 'text-white bg-primary duration-500 transition-all'
							: 'text-primary'
					}
          z-[1] capitalize lg:text-base text-xs hover:border-2 border-2 border-transparent py-3 lg:px-5 px-3 hover:border-primary rounded-full`}
				>
					Virtual Office Plans
				</button>
			</div>

			{plan === 'Co-working Plan' ? (
				<Carousel {...carouselParams}>
					<PricingCard
						name="Part-Time Plan"
						title="Ideal for those who only need workspace part-time."
						price={{ amount: 2000, period: 'Daily' }}
						plans={PartTimePlan}
						btnText="Book Now"
						trail="Try it out"
					/>
					<PricingCard
						name="Hot Desk Plan"
						title="Ideal for freelancers and remote workers"
						price={HotDeskPrice}
						plans={HotDeskPlans}
						btnText="Book Now"
						trail="Try it out"
					/>
					<div className="relative">
						<span className="absolute -top-1 left-10 bg-primary text-white px-2 py-1 rounded-md">
							Suggested
						</span>
						<PricingCard
							name="Dedicated Desk Plan"
							title="Exclusive use of a dedicated desk in an open workspace"
							price={{ amount: 30000, period: 'Monthly' }}
							plans={DedicatedDeskPlan}
							btnText="Start now"
							trail="Book Now"
						/>
					</div>
					<div className="relative">
						<span className="absolute -top-1 left-10 bg-primary text-white px-2 py-1 rounded-md">
							Suggested
						</span>
						<PricingCard
							name="Meeting Room Plan"
							title="For pro level developers"
							price={MeetingRoomPrice}
							btnText="Start now"
							trail="Book Now"
							plans={MeetingRoomPlan}
						/>
					</div>
					<div className="relative">
						<span className="absolute -top-1 left-10 bg-primary text-white px-2 py-1 rounded-md">
							Suggested
						</span>
						<PricingCard
							name="Community Event"
							title="Access to main floor"
							price={MeetingRoomPrice}
							btnText="Start now"
							trail="Book Now"
							plans={MeetingRoomPlan}
						/>
					</div>
					<div className="relative">
						<span className="absolute -top-1 left-10 bg-primary text-white px-2 py-1 rounded-md">
							Suggested
						</span>
						<PricingCard
							name="Corporate Plan"
							title="Board rooms and meeting rooms"
							price={CorporatePlanPrice}
							btnText="Start now"
							trail="Book Now"
							plans={CorporatePlan}
						/>
					</div>
				</Carousel>
			) : (
				<Carousel {...carouselParams}>
					<PricingCard
						name="Basic Office Plan"
						title="Enjoy discounted access to meeting rooms"
						price={{ amount: 300000, period: 'Yearly' }}
						plans={BasicOfficePlan}
						btnText="Book Now"
						trail="Try it out"
					/>
					<div className="relative">
						<span className="absolute -top-1 left-10 bg-primary text-white px-2 py-1 rounded-md">
							Suggested
						</span>
						<PricingCard
							name="Standard Office Plan"
							title="Enjoy discounted access to meeting rooms"
							price={{ amount: 400000, period: 'Yearly' }}
							plans={StandardOfficePlan}
							btnText="Start now"
							trail="Oya Book Now"
						/>
					</div>
					<div className="relative">
						<span className="absolute -top-1 left-10 bg-primary text-white px-2 py-1 rounded-md">
							Suggested
						</span>
						<PricingCard
							name="Premium Office Plan"
							title="Enjoy discounted access to meeting rooms"
							price={{ amount: 650000, period: 'Yearly' }}
							plans={PremiumOfficePlan}
							btnText="Start now"
							trail="Book Now"
						/>
					</div>
					<div className="relative">
						<span className="absolute -top-1 left-10 bg-primary text-white px-2 py-1 rounded-md">
							Suggested
						</span>
						<PricingCard
							name="Custom Office Plan"
							title="Enjoy discounted access to meeting rooms"
							price={{ amount: 500000, period: 'Yearly' }}
							btnText="Start now"
							trail="Book Now"
							plans={CustomOfficePlan}
						/>
					</div>
				</Carousel>
			)}
		</section>
	);
};

export default Pricing;

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 4,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const carouselParams = {
	additionalTransfrom: 0,
	arrows: false,
	autoPLaySpeed: 3000,
	centerMode: false,
	className: '',
	containerClass: 'carousel-container',
	customButtonGroup: <ButtonGroup />,
	dotListClass: '',
	draggable: true,
	focusOnSelect: false,
	infinite: true,
	itemClass: '',
	keyBoardControl: true,
	minimumTouchDrag: 80,
	renderButtonGroupOutside: true,
	renderDotsOutside: false,
	responsive: responsive,
	showDots: false,
	sliderClass: '',
	slidesToSlide: 1,
};

import { FaStar, FaStarHalf } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from "./ButtonGroup";

const Card = ({ star, title, imgSrc, testimony, name, occupation }) => {
  return (
    <div className="mx-2 font-dm  flex flex-col gap-2 select-none text-black dark:text-white border border-[#4e4e4e31] p-5 rounded-lg hover:shadow-xl transition-all">
      <div className="text-primary flex gap-1">
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        {star}
      </div>
      {/* <h2 className="text-lg font-semibold  mb-2">{title}</h2> */}
      <p className="leading-loose text-xs">{testimony}</p>
      <div className="flex items-center gap-2 mt-2">
        <img
          className=" rounded-lg w-14 aspect-square object-cover object-top"
          src={imgSrc}
          width={40}
          height={40}
          alt="testimonials"
        />
        <div className="flex flex-col gap-px">
          <h3 className="font-semibold text-sm">{name}</h3>
          <p className="text-primary font-[500] text-xs">{occupation}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  return (
    <section
      className="relative section container mx-auto px-0 md:px-16 flex flex-col gap-5"
      id="testimonial"
    >
      <div>
        <span className="service-name text-center ">TESTIMONIAL</span>
        <h2 className="text-center section-title">Meet Client Satisfaction</h2>
      </div>
      <Carousel {...carouselParams}>
        <div>
          <Card
            star={<FaStarHalf />}
            testimony="I can't say enough about the fantastic experience I've had at OfficeBox. The co-working spaces are modern and comfortable, the internet is fast, and the coffee is top-notch. But what truly sets OfficeBox apart is the sense of community. I've met incredible people here, and the networking opportunities have been invaluable for my business. I highly recommend OfficeBox to anyone looking for a dynamic and collaborative workspace."
            imgSrc="/testimonials/tolu.jpg"
            name="Tolu"
            occupation="Founder of ART SPACE"
          />
        </div>
        <div>
          <Card
            star={<FaStar />}
            title="Design Quality & performance"
            imgSrc="/testimonials/Aisha.jpg"
            name="Aisha Ahmad"
            occupation="BANKER"
            testimony="As a small business owner, OfficeBox has been a game-changer for me. The consulting services provided have helped me streamline my operations and improve my overall efficiency. The co-working space is a breath of fresh air, and the diverse community has opened up exciting collaboration opportunities. OfficeBox is more than just a workspace; it's a place where dreams and ideas come to life."
          />
        </div>
        <div>
          <Card
            star={<FaStar />}
            title="Layout and organized layers"
            imgSrc="/testimonials/Ahmad.jpg"
            name="Ahmad"
            occupation="Software Developer"
            testimony="I've attended several software training sessions at OfficeBox, and I've been thoroughly impressed with the quality of instruction. The trainers are knowledgeable and experienced, and the hands-on approach to learning is incredibly effective. The skills I've acquired have been a game-changer for my career. OfficeBox is my go-to place for both work and professional development."
          />
        </div>
        {/* <div>
          <Card
            star={<FaStarHalf />}
            title="Layout and organized layers"
            imgSrc="/testimonials/3.png"
          />
        </div> */}
      </Carousel>
    </section>
  );
};

export default Testimonials;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  arrows: false,
  autoPLaySpeed: 100,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  customButtonGroup: <ButtonGroup />,
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 1,
};
